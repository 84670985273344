<template>
  <div
    class="generate-password txt--12px txt--weight-600 txt--height-16px txt--main"
    @click="onClickGeneratePassword"
  >
    <span>
      <key-icon class="txt--main mr-6" />
      {{ $t('auth.generatePassword') }}
    </span>
  </div>
</template>

<script>
import { generateStrongPassword } from '@/lib/utils'

export default {
  name: 'GeneratePassword',
  components: {
    KeyIcon: () =>
      import(/* webpackMode: "eager" */ '@/assets/icons/key-icon.svg?inline'),
  },
  methods: {
    onClickGeneratePassword () {
      const pass = generateStrongPassword()
      this.$emit('generate', pass)
    },
  },
}
</script>

<style lang="scss" scoped>
.generate-password {
  cursor: pointer;
  user-select: none;
}
</style>
