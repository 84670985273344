export default {
  beforeRouteEnter (_to, from, next) {
    next((vm) => {
      vm.$store.commit('ieo/setIsFromIeoPage', undefined)
      // if comes from ieo page - after login redirects back to this page
      if (from.name && from.name.includes('launchpad')) {
        vm.$store.commit('ieo/setIsFromIeoPage', from)
      }
    })
  },
}
