<template>
  <div class="password-input">
    <tdx-input
      :value="value"
      v-bind="{ ...$attrs }"
      :type="inputType"
      :size="size"
      class="password-input__input"
      :class="bgTheme"
      @input="inputHandler"
    />
    <div
      class="generate-password"
      @click="onClickGeneratePassword"
    >
      <span>
        <key-icon class="txt--theme-09 mr-8" />
      </span>
    </div>
    <div
      class="password-input__icon"
      @click="toggleType"
    >
      <hide-icon
        v-if="showValue"
        class="txt--theme-08"
      />
      <show-icon
        v-else
        class="txt--theme-08"
      />
    </div>
  </div>
</template>

<script>
import { generateStrongPassword } from '@/lib/utils'

import { TdxInput } from '@/components/common'

export default {
  name: 'PasswordInputWithKey',
  components: {
    TdxInput,
    HideIcon: () => import(/* webpackMode: "eager" */ '@/assets/icons/trade/hide-pairs.svg?inline'),
    ShowIcon: () => import(/* webpackMode: "eager" */ '@/assets/icons/trade/show-pairs.svg?inline'),
    KeyIcon: () => import(/* webpackMode: "eager" */ '@/assets/icons/key-icon-new.svg?inline'),
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    bgTheme: {
      type: String,
      default: 'bg--theme-03',
    },
    size: {
      type: String,
      default: 's',
    },
  },
  data () {
    return {
      showValue: false,
    }
  },
  computed: {
    inputType () {
      return this.showValue ? 'text' : 'password'
    },
  },
  methods: {
    toggleType () {
      this.showValue = !this.showValue
    },
    inputHandler (value) {
      this.$emit('input', value)
    },
    onClickGeneratePassword () {
      const pass = generateStrongPassword()
      this.$emit('generate', pass)
    },
  },
}
</script>

<style lang="scss" scoped>
.password-input {
  position: relative;

  &__icon {
    position: absolute;
    right: toRem(25px);
    bottom: toRem(13px);
    cursor: pointer;
  }

  &__input {
    &::v-deep {
      input {
        padding-right: 30px;
      }
    }
  }
}

.generate-password {
  cursor: pointer;
  user-select: none;
  position: absolute;
  right: toRem(49px);
  bottom: toRem(12px);
}
</style>
